



















import { Component, Vue } from 'vue-property-decorator';
import FlagshipGoalsStepInitViewModel
  from '@/vue-app/view-models/components/flagship/flagship-goals/flagship-goals-step-init-view-model';

@Component({ name: 'FlagshipGoalsStepInit' })
export default class FlagshipGoalsStepInit extends Vue {
  step_init_model = Vue.observable(new FlagshipGoalsStepInitViewModel());
}
